import { cloneDeep } from "lodash";


export  const handleNameChange = (set, event) => {
    set(event.target.value)
  }

  export  const onCheckRequired = (set, value) => {
    set(value)
  }

  export  const onCheckTypeQuestionOption= (set, value) => {
    set(value)
  }

  export const guidGenerator = () => {
    const S4 = () => {
      return (((1 + Math.random()) *0x10000) | 0)
      .toString(16)
      .substring(1)
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() +"-" + S4() + S4() + S4());
  };

 export  const removeNull = (array)=>{
    const newTableData = cloneDeep(array)
    const newList = []
    newTableData.map((item=>{
      for (const key in item) {
        if (item[key] === null) {
          delete item[key];
        }
      }
      newList.push(item);
    }))
    return newList
  
  }


  export const addPriority =(array)=>{
    let i = {}
    let newArray = []
    array.map((item, index)=>{
     i = {...item, priority: index}
     newArray.push(i)
    })
    return (newArray)
  
  }
  
  
 export  const questionExist= (questionList, uniqueUuid)=>{
    const isExist = questionList.find( item=>item.uuid === uniqueUuid || item.uuid2 === uniqueUuid  )
    if (isExist) return true;
    return false;
  }


  export  const setOptionIncidentCreate = (set, incident, index) => {
    set (prevOption => {
        const mutatedPrevOption = prevOption.map((option, optionIndex) => {
            if (index === optionIndex) {
              if(incident === false) {option.incidents = false}
              else  option.incidents = incident
            }
            return option
        })
        return [...mutatedPrevOption]
    })
}
export  const setOptionPenalityCreate = (set, penality, index) => {
  set (prevOption => {
      const mutatedPrevOption = prevOption.map((option, optionIndex) => {
          if (index === optionIndex) {
              option.penality = penality
          }
          return option
      })
      return [...mutatedPrevOption]
  })
}

export const setOptionIncident = (set, incident, id) => {
  set((prevOption) => {
    const mutatedPrevOption = prevOption.map((option, optionIndex) => {
      if (id === option.uuid || id === option.uuid2  ) {
        // Crear una copia del objeto 'option' antes de modificarlo
        const updatedOption = { ...option, incidents: incident === false ? false : incident };
        return updatedOption; // Devolver la copia actualizada
      }
      return option; // Devolver el objeto sin modificar
    });
    return [...mutatedPrevOption]; // Devolver el nuevo array con los objetos actualizados
  });
};

export const setOptionPenality = (set, penality, id) => {
  set((prevOption) => {
    const mutatedPrevOption = prevOption.map((option, optionIndex) => {
      if (id === option.uuid || id === option.uuid2  ) {
        // Crear una copia del objeto 'option' antes de modificarlo
        const updatedOption = { ...option, penality: penality === false ? false : penality };
        return updatedOption; // Devolver la copia actualizada
      }
      return option; // Devolver el objeto sin modificar
    });
    return [...mutatedPrevOption]; // Devolver el nuevo array con los objetos actualizados
  });
};

export   const filterVisibleQuestions = (array) => {
  const visibleQuestions = array.filter(question => question.visible === true);
  return visibleQuestions;
};
export   const filterVisibleAndIncidenceQuestions = (array) => {
  const visibleQuestions = array.filter(question => question.visible === true && question.incidents === true);
  return visibleQuestions;
};

export const validateOptionIncident = (array)=>{
 return array.some((element)=> element.incidents === true)
}

export const optionPoints = [
  { value: 0, label: 0 },
  { value: 0.15, label: 0.15 },
  { value: 0.2, label: 0.2 },
  { value: 0.25, label: 0.25 },
  { value: 0.46, label: 0.46 },
  { value: 0.47, label: 0.47 },
  { value: 0.5, label: 0.5 },
  { value: 0.6, label: 0.6 },
  { value: 0.75, label: 0.75 },
  { value: 1, label: 1 },
  { value: 1.2, label: 1.2 },
  { value: 1.4, label: 1.4 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 }
];

export const removeInvisibleOptions = (options) => {
  return options.filter(option => option.visible);
};